import {
    Button,
    Flex,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useDisclosure,
    Select,
    FormControl,
    FormLabel ,
    Input,
    Center
  } from "@chakra-ui/react";
  import useAxios from "axios-hooks";
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader";
  import React, { useState, useEffect } from "react";
  import { API_ROUTES , ROOT_API } from "utils/constant";
  import { TablePagination } from "@trendmicro/react-paginations";
  import { initialFilter } from "utils/constant";
  import { useLocation } from "react-router-dom";
  import { axiosGet } from "utils/api";
  import ListTrackingNation from "components/Tracking/LisTrackingNation";
  import axios from "axios";
  
  const TrackingNation = () => {
    const [nationTracking , setNationTracking] = useState()
    const [filter, setFilter] = useState(initialFilter);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const isRegisterOpen = isOpen;
    const onRegisterOpen = onOpen;
    const onRegisterClose = onClose;
    const trackingApi = ROOT_API + API_ROUTES.LIST_TRACKING ;
    const location = useLocation();
    const spliceSubDomain = location.pathname.match(/\/subDomain\/([^/]+)\//);
    const subDomainId = spliceSubDomain[1]
    const [{ data, loading, error }, refetch] = useAxios({
      url: `${trackingApi}-nation/${subDomainId}`,
      params: filter,
    });
    useEffect(() =>{
        setNationTracking(data?.data)
    },[data])

    
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const handleEditClick = (row) => {
        setSelectedRow(row);
        setIsEditModalOpen(true);
    };

  const handleGetNationTracking = async () => {
    const url = `${trackingApi}-nation/${subDomainId}`;
    const params = { startDate, endDate, typeNation, ...filter };
  
    const fullUrl = `${url}?${new URLSearchParams(params).toString()}`;
    try {
      const { data } = await axiosGet(fullUrl, {
        params: { startDate, endDate, typeNation, ...filter },
      });
      setNationTracking(data.data)
    } catch (error) {
      console.error('Export failed:', error);
    }
  };

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [typeNation, setTypeNation] = useState('');
  const [valueStartDate , setValueStartDate] = useState()
  const [valueEndDate , setValueEndData] = useState()
  const startDateChange = (event) => {
    setValueStartDate(event.target.value)
    setStartDate(new Date(event.target.value).toISOString());
  };
  const endDateChange = (event) => {
    setValueEndData(new Date(event.target.value).toISOString())
    setEndDate(event.target.value);
  };
  const nationChange = (event) => {
    setTypeNation(event.target.value);
  };
  const clearFilter = () => {
    setStartDate('');
    setEndDate('');
    setTypeNation('');
    setValueStartDate('');
    setValueEndData('');
  }
  const handleExportTrackingNation = async () => {
    const type = 'export'
    try {
    const url = `${trackingApi}-nation/${subDomainId}`;
    const params = { startDate, endDate, typeNation,type, ...filter };
    const fullUrl = `${url}?${new URLSearchParams(params).toString()}`;
      const response = await axios.get(fullUrl, {
        responseType: 'blob',
      });
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'exportedSubDomain.xlsx'; 
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  } catch (error) {
    console.error("Error exporting tracking:", error);
  }

  }
  
    return (
        <>
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Nation Tracking
          </Text>
            <Button
              variant="primary"
              maxH="30px"
              m="10px"
              onClick={() => {
                handleExportTrackingNation();
              }}
              // isLoading={exporting} 
            >
              Export
            </Button>
            
            
            <Flex> 
            {/* <FormLabel maxH="30px"
            m="10px"
            maxWidth="10%"  htmlFor="IP">IP</FormLabel>
            <Select placeholder=''
            maxH="30px"
            m="10px"
            maxWidth="10%">
              <option value='option1'>All</option>
              <option value='option2'>Uniqued</option>
            </Select>
          */}
        
            <FormLabel maxH="30px"
            paddingTop="7px"
            m="10px"
            maxWidth="10%"  htmlFor="Nation">Nation</FormLabel>
            <Select placeholder='All'
            maxH="30px"
            m="10px"
            maxWidth="10%"
            value={typeNation}
            onChange={nationChange}
            >
              <option value='unique'>Uniqued</option>
            </Select>
            <FormLabel maxH="30px"
            paddingTop="7px"
            m="10px"
            maxWidth="10%"  htmlFor="Nation">Start Date</FormLabel>
            <Input
              maxH="60px"
              m="10px"
              maxWidth="10%"
              placeholder="Select Date"
              size="md"
              type="date"
              id="date"
              value={valueStartDate}
              onChange={startDateChange}
            />
            <FormLabel maxH="30px"
            paddingTop="7px"
            m="10px"
            maxWidth="10%"  htmlFor="Nation">End Date</FormLabel>
            <Input
              maxH="60px"
              m="10px"
              maxWidth="10%"
              placeholder="Select Date"
              size="md"
              type="date"
              id="date"
              value={valueEndDate}
              onChange={endDateChange}
            />
            <Button
              variant="primary"
              maxH="30px"
              m="10px"
              onClick={() => {
                handleGetNationTracking();
              }}
            >
              Filter
            </Button>
            <Button
              marginTop="15px"
              variant="primary"
              maxH="30px"
              m="10px"
              onClick={() => {
                clearFilter();
              }}
            >
              Clear Filter
            </Button>
            </Flex> 
        </CardHeader>
          <CardBody>
            <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  {/* <Th borderColor={borderColor} color="gray.400">
                  IP
                  </Th> */}
                  <Th borderColor={borderColor} textAlign="center" color="gray.400">
                  Nation
                  </Th>
                  <Th borderColor={borderColor}></Th>
                  <Th borderColor={borderColor} textAlign="center" color="gray.400">
                  Quantity
                  </Th>
                  <Th borderColor={borderColor}></Th>
                  <Th borderColor={borderColor} textAlign="center" color="gray.400">
                  Date
                  </Th>
                  <Th borderColor={borderColor}></Th>
                  
                </Tr>
              </Thead>
                <Tbody>
                  {nationTracking?.map((row, index, arr) => (
                    <ListTrackingNation
                      quantity={row.count === undefined ? 1 : row.count}
                      key={row._id}
                      id={row._id}
                      subdomain={row.subdomain}
                      ip={row.ip}
                      startday={row.createdAt}
                      nation={row.nation === undefined ? row._id :row.nation }
                      refetch = {refetch}
                      onClick={() => handleEditClick(row)}
                    />
                    ))}
                </Tbody>
            </Table>
            <Flex justifyContent={"flex-end"}>
              <TablePagination
                  type="full"
                  page={data?.pagination?.page}
                  pageLength={data?.pagination?.pageSize}
                  totalRecords={data?.pagination?.count}
                  onPageChange={({ page, pageLength }) => {
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  }}
                  prevPageRenderer={() => <i className="fa fa-angle-left" />}
                  nextPageRenderer={() => <i className="fa fa-angle-right" />}
                />
            </Flex>
          </CardBody>
        </Card>
      </Flex>
    </>
    )
  }
  
  export default TrackingNation;