import {
    Button,
    Flex,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useDisclosure,
    Select,
    FormControl,
    FormLabel ,
    Input,
    Center
  } from "@chakra-ui/react";
  import useAxios from "axios-hooks";
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader";
  import ListTracking from "components/Tracking/ListTrackingIP";
  import React, { useState, useEffect } from "react";
  import { API_ROUTES , ROOT_API } from "utils/constant";
  import { TablePagination } from "@trendmicro/react-paginations";
  import { initialFilter } from "utils/constant";
  import { useLocation } from "react-router-dom";
  import { axiosGet } from "utils/api";
  import axios from "axios";
  
  const Tracking = () => {
    const [filter, setFilter] = useState(initialFilter);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const isRegisterOpen = isOpen;
    const onRegisterOpen = onOpen;
    const onRegisterClose = onClose;
    const trackingApi = ROOT_API + API_ROUTES.LIST_TRACKING ;
    const location = useLocation();
    const spliceSubDomain = location.pathname.match(/\/subDomain\/([^/]+)\//);
    const subDomainId = spliceSubDomain[1]
    const [ipTracking , setIpTracking] = useState()

    
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [typeIP, setTypeIP] = useState('');
    const [valueStartDate , setValueStartDate] = useState()
    const [valueEndDate , setValueEndData] = useState()


    const [{ data, loading, error }, refetch] = useAxios({
      url: `${trackingApi}-ip/${subDomainId}`,
      params:{...filter}
    });
    useEffect(() =>{
      setIpTracking(data?.data)
  },[data])
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const handleEditClick = (row) => {
        setSelectedRow(row);
        setIsEditModalOpen(true);
    };
    const [exporting, setExporting] = useState(false);
  const handleExportTrackingIP = async () => {
    const type = 'export'
    try {
      const url = `${trackingApi}-ip/${subDomainId}`;
      const params = { startDate, endDate, typeIP,type, ...filter };
      const fullUrl = `${url}?${new URLSearchParams(params).toString()}`;
      const response = await axios.get(fullUrl, {
        responseType: 'blob',
      });
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'exportedSubDomain.xlsx'; 
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Export failed:', error);
    } finally {
      setExporting(false); 
    }
  };
  const handleGetIpTracking = async () => {

    const url = `${trackingApi}-ip/${subDomainId}`;
    const params = { startDate, endDate, typeIP, ...filter };
    const fullUrl = `${url}?${new URLSearchParams(params).toString()}`;
    try {
      const { data } = await axiosGet(fullUrl, {
        params: { startDate, endDate, typeIP, ...filter },
      });
      setIpTracking(data?.data)
    } catch (error) {
      console.error('Export failed:', error);
    }
  };

  const startDateChange = (event) => {
    setValueStartDate(event.target.value)
    setStartDate(new Date(event.target.value).toISOString());
  };
  const endDateChange = (event) => {
    setValueEndData(event.target.value)
    setEndDate(new Date(event.target.value).toISOString());
  };
  const ipChange = (event) => {
    setTypeIP(event.target.value);
  };

  const clearFilter = () => {
    setStartDate('');
    setEndDate('');
    setTypeIP('');
    setValueStartDate('');
    setValueEndData('');
  }
  
    return (
        <>
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            IP Tracking
          </Text>
            <Button
              variant="primary"
              maxH="30px"
              m="10px"
              onClick={() => {
                handleExportTrackingIP();
              }}
              isLoading={exporting} 
            >
              Export
            </Button>
            
            <Flex> 
            <FormLabel maxH="30px"
            m="10px"
            paddingTop="7px"
            maxWidth="10%"  htmlFor="IP">IP</FormLabel>
            <Select placeholder='All'
            maxH="30px"
            m="10px"
            maxWidth="10%"
            value={typeIP}
            onChange={ipChange}
              >
              {/* <option value='all'>All</option> */}
              <option value='unique'>Uniqued</option>
            </Select>
         
        
           
            <FormLabel maxH="30px"
            paddingTop="7px"
            m="10px"
            maxWidth="10%" htmlFor="Nation">Start Date</FormLabel>
            <Input
              maxH="60px"
              m="10px"
              maxWidth="10%"
              placeholder="Select Date"
              size="md"
              type="datetime-local"
              value={valueStartDate}
              onChange={startDateChange}
            />
            <FormLabel maxH="30px"
            paddingTop="7px"
            m="10px"
            maxWidth="10%" htmlFor="Nation">End Date</FormLabel>
            <Input
              maxH="60px"
              m="10px"
              maxWidth="10%"
              placeholder="Select Date"
              size="md"
              type="datetime-local"
              value={valueEndDate}
              onChange={endDateChange}
            />
            <Button
              variant="primary"
              marginTop="15px"
              maxH="30px"
              m="10px"
              onClick={() => {
                handleGetIpTracking();
              }}
            >
              Filter
            </Button>
            <Button
              marginTop="15px"
              variant="primary"
              maxH="30px"
              m="10px"
              onClick={() => {
                clearFilter();
              }}
            >
              Clear Filter
            </Button>
            </Flex> 
        </CardHeader>
          <CardBody>
            <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  <Th borderColor={borderColor} color="gray.400">
                  IP
                  </Th>
                  {/* <Th borderColor={borderColor} textAlign="center" color="gray.400">
                  Nation
                  </Th> */}
                  <Th borderColor={borderColor}></Th>
                  <Th borderColor={borderColor} textAlign="center" color="gray.400">
                  Quantity
                  </Th>
                  <Th borderColor={borderColor}></Th>
                  <Th borderColor={borderColor} textAlign="center" color="gray.400">
                  Date
                  </Th>
                  <Th borderColor={borderColor}></Th>
                </Tr>
              </Thead>
              <Tbody>
                  {ipTracking?.map((row, index, arr) => (
                    <ListTracking
                      quantity={row.count === undefined ? 1 : row.count}
                      key={row._id}
                      id={row._id}
                      subdomain={row.subdomain}
                      ip={row.ip === undefined ? row._id : row.ip } 
                      startday={row.date=== undefined ? row.createdAt : row.date}
                      nation={row.nation}
                      refetch = {refetch}
                      onClick={() => handleEditClick(row)}
                    />
                    ))}
                </Tbody>
            </Table>
            <Flex justifyContent={"flex-end"}>
              <TablePagination
                  type="full"
                  page={data?.pagination?.page}
                  pageLength={data?.pagination?.pageSize}
                  totalRecords={data?.pagination?.count}
                  onPageChange={({ page, pageLength }) => {
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  }}
                  prevPageRenderer={() => <i className="fa fa-angle-left" />}
                  nextPageRenderer={() => <i className="fa fa-angle-right" />}
                />
            </Flex>
          </CardBody>
        </Card>
      </Flex>
    </>
    )
  }
  
  export default Tracking;